import { render, staticRenderFns } from "./snapshot.vue?vue&type=template&id=18d3c630&scoped=true&"
import script from "./snapshot.vue?vue&type=script&lang=js&"
export * from "./snapshot.vue?vue&type=script&lang=js&"
import style0 from "./snapshot.vue?vue&type=style&index=0&id=18d3c630&prod&scoped=true&lang=css&"
import style1 from "./snapshot.vue?vue&type=style&index=1&id=18d3c630&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d3c630",
  null
  
)

export default component.exports