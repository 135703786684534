<template>
    <div class="container">
        <!-- <RightHeader :companyObj="companyBase" @translate="getIsTranslate"></RightHeader> -->
        <div class="sna_box flex_between_box">
            <div class="left_mess">
                <div class="left_mess_bottom">
                    <!-- <div v-if="$route.query.type == 'Person'" class="mess_item flex_box">
                        <label class="lab">Gender:</label>
                        <div class="txt">{{ $route.query.gender ? $route.query.gender : '' }}</div>
                    </div> -->
                    <div v-if="$route.query.type != 'Person'" class="mess_item flex_box">
                        <label class="lab">Late:</label>
                        <div v-show="!showTranslate" class="txt">{{ $route.query.subsidiary ? 'NO' : 'YES' }}</div>
                        <div v-show="showTranslate" class="txt">{{ $route.query.subsidiary ? 'NO' : 'YES' }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Type:</label>
                        <div v-show="!showTranslate" class="txt">{{ $route.query.type ? $route.query.type : '' }}</div>
                        <div v-show="showTranslate" class="txt">{{ $route.query.type ? $route.query.type : '' }}</div>
                    </div>
                    <div class="mess_item flex_box">
                        <label class="lab">Last Reviewed:</label>
                        <div class="txt flex_align_center">
                            {{ timers }}
                        </div>
                    </div>
                    <div v-if="$route.query.type == 'Person'">
                        <div class="mess_item flex_box" v-if="person?.places_of_birth">
                            <label class="lab">Birth:</label>

                            <div class="txt">
                                {{ person.places_of_birth ? person.places_of_birth[0].country.descriptor : '' }}
                            </div>
                        </div>
                        <div class="mess_item flex_box">
                            <label class="lab">citizenship:</label>

                            <div class="txt">
                                {{ person.country_territory_details ? person.country_territory_details.citizenship[0].descriptor : '' }}
                            </div>
                        </div>
                        <div class="mess_item flex_box" v-if="person?.country_territory_details?.residence">
                            <label class="lab">resident Of:</label>

                            <div class="txt">
                                <span v-for="(item, index) in person?.country_territory_details?.residence" :key="index">{{ item.descriptor }}</span>
                            </div>
                        </div>
                        <div class="mess_item flex_box" v-if="person?.country_territory_details?.jurisdiction">
                            <label class="lab">jurisdiction:</label>

                            <div class="txt">
                                <span v-for="(item, index) in person?.country_territory_details?.jurisdiction" :key="index">{{ item.descriptor }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="$route.query.type != 'Person'">
                        <div v-if="zhuce1" class="mess_item flex_box">
                            <label class="lab">Registration:</label>
                            <div class="txt">{{ zhuce1.descriptor }}</div>
                        </div>
                        <div v-if="lianshu" class="mess_item flex_box">
                            <label class="lab">Affiliation:</label>
                            <div class="txt">
                                <span v-for="(item, index) in lianshu.affiliation" :key="index">{{ item.descriptor }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="mess_item flex_box" v-if="zhuce">
                        <label class="lab">Dates:</label>

                        <div class="txt">
                            <div class="information-box">
                                <div class="information-box-text1" v-if="$route.query.type == 'Person'">BIRTH:</div>
                                <div class="information-box-text1" v-if="$route.query.type == 'Entity'">REGISTRATION:</div>

                                <div v-if="zhuce?.month > 0" class="information-box-text">
                                    Time:

                                    {{ zhuce?.year }}-{{ zhuce?.month }}-{{ zhuce?.day }}
                                </div>
                                <div v-else class="information-box-text">Time:</div>
                            </div>
                        </div>
                    </div>
                    <div class="mess_item flex_box" style="margin-bottom: 0px">
                        <label class="lab">Wathchlist:</label>

                        <div class="txt">
                            <span v-if="Wathchlist.active_status == 'Active'" class="information-box-text">ACTIVE</span>
                            <span v-if="Wathchlist.active_status == 'InActive'" class="information-box-text">INACTIVE</span>
                        </div>
                    </div>
                    <!-- <div class="mess_item flex_box">
                        <div class="name-overview-box-text" v-for="(item, index) in Wathchlist?.content_types" :key="index">
                            <h1 style="background-color: #fff; font-size: 15px; line-height: 25px; color: #022955">
                                {{ item.description }}
                                <i v-if="item.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span class="information-box-text">ACTIVE</span></i>
                                <i v-if="item.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span class="information-box-text">INACTIVE</span></i>
                            </h1>
                            <div v-show="item.sub_content_types" style="padding-left: 20px" v-for="(ite, ind) in item.sub_content_types" :key="ind">
                                <div class="linheight information-box-text" style="background-color: #fff; font-size: 14px">
                                    {{ ite.description }}
                                    <i v-if="ite.active_status == 'Active'" style="color: rgb(16, 189, 133); margin-left: 10px" class="el-icon-success"><span class="information-box-text">ACTIVE</span></i>
                                    <i v-if="ite.active_status == 'InActive'" style="color: rgb(251, 80, 80); margin-left: 10px" class="el-icon-error"><span class="information-box-text">INACTIVE</span></i>
                                </div>
                                
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="right_coll">
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img1 flex_center">
                                <img src="../../assets/img/detail/shenfenzheng.png" />
                            </div>
                            <div class="des">
                                <div class="title">Subject Type</div>
                                <div class="txt">{{ $route.query.type }}</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" v-show="checkIn1">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" v-show="!checkIn1">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img2 flex_center">
                                <img src="../../assets/img/word.png" />
                            </div>
                            <div class="des">
                                <div class="title">Country Or Territory</div>
                                <div v-if="$route.query.type == 'Person'">
                                    <div class="txt" v-if="$route.query.country">{{ $route.query.country }}</div>
                                    <div class="txt" v-else>{{ person.country_territory_details ? person.country_territory_details.citizenship[0].descriptor : '' }}</div>
                                </div>
                                <div v-else>
                                    <div class="txt" v-if="$route.query.country">{{ $route.query.country }}</div>
                                    <div class="txt" v-else>{{ zhuce1.descriptor }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" v-show="checkIn2">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" v-show="!checkIn2">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img3 flex_center">
                                <img src="../../assets/img/yueshu.png" />
                            </div>
                            <div class="des">
                                <div class="title">Sanction Lists</div>
                                <div class="txt" v-if="watchlisttable.length > 0">Active</div>
                                <div class="txt" v-else>Inactive</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" v-show="checkIn3">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" v-show="!checkIn3">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="coll_item">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="img img4 flex_center">
                                <img src="../../assets/img/mingdan.png" />
                            </div>
                            <div class="des">
                                <div class="title">Other Official Lists</div>

                                <div class="txt" v-if="watchlistothertable.length > 0">Active</div>
                                <div class="txt" v-else>Inactive</div>
                            </div>
                        </div>
                        <div class="btn_show">
                            <div class="check_in" v-show="checkIn4">
                                <img src="../../assets/img/search/menu_ic1.png" />
                            </div>
                            <div class="check_out" v-show="!checkIn4">
                                <img src="../../assets/img/search/menu_ic2.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="right_coll">
                <div class="coll_item coll_item1">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="des">
                                <div class="title">SUBJECT TYPE</div>
                            </div>
                        </div>
                    </div>
                    <div class="text">{{ $route.query.type }}</div>
                </div>
                <div class="coll_item coll_item2">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="des">
                                <div class="title">
                                    COUNTRY OR
                                    <br />
                                    TERRITORY
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="$route.query.type=='Person'">
                        <div class="text" v-if="$route.query.country">{{ $route.query.country }}</div>
                        <div class="text" v-else>{{ person.country_territory_details ? person.country_territory_details.citizenship[0].descriptor : '' }}</div>
                    </div>
                    <div v-else>
                        <div class="text" v-if="$route.query.country">{{ $route.query.country }}</div>
                        <div class="text" v-else>{{ zhuce1.descriptor  }}</div>
                    </div>
                </div>
                <div class="coll_item coll_item3">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="des">
                                <div class="title">SANCTION LISTS</div>
                            </div>
                        </div>
                    </div>
                    <div class="text" v-if="watchlisttable.length > 0">Active</div>
                    <div class="text" v-else>Inactive</div>
                </div>
                <div class="coll_item coll_item4">
                    <div class="coll_top flex_center_between_box">
                        <div class="left flex_box">
                            <div class="des">
                                <div class="title">
                                    OTHER OFFICIAL
                                    <br />
                                    LISTS
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text" v-if="watchlistothertable.length > 0">Active</div>
                    <div class="text" v-else>Inactive</div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { searchDetailsById } from '@/api/searchApi.js';
import { getCompanySnap } from '@/api/companyApi.js';
import { mixins1 } from '@/mixins/index';
import crypto from '../../utils/crypto';
import bus from '../../components/bus';
export default {
    mixins: [mixins1],

    data() {
        return {
            obj: {
                RegID: '',
                Address: '',
                addressCn: '',
                cityCn: '',
                City: '',
                Country: '',
                img: '',
                Website: '',
                Email: '',
                GoodStanding: '',
                Form: '',
                Year: '',
                Status: '',
                Listed: '',
                GCaution: '',
                SIC: '',
                SICCont: '',
                Activity: '',
                Products: '',
                Average: '',
                Business: '',
                Revenue: '',
                Assets: '',
                ProfitLoss: '',
                Employee: '.',
                clear: '',
                Bankruptcy: '',
                Litigations: '',
                Sanctions: '',
                Lien: '',
                lastUpdate: '',
                dataSource: [],
            },

            checkIn1: true,
            checkIn2: true,
            checkIn3: true,
            checkIn4: true,
            DataQuality: 0,
            CreditScore: 0,
            newDialog: false,
            zhuce: {},
            zhuce1: {},
            sources: [],
            Wathchlist: [],
            images: [],
            timers: '',
            last_reviewed_date: '',
            watchlisrole: { primary: [] },
            hostrytable: [],
            lianshu: {},
            person: {},
            watchlistothertable: [],
            active_status: '',
            watchlisttable: [],

            // showTranslate: false, //翻译
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    mounted() {
        this.getdetail();
        window.addEventListener('setItem', () => {
            this.token = sessionStorage.getItem('token');
        });
    },
    created() {},
    methods: {
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                // console.log(`res`, res);
                let nums = [];
                let nums1 = [];
                // this.Relationstable=
                // this.page.total=this.Relationstable.length
                if (this.$route.query.type == 'Person') {
                    this.images = res.data.attributes.image ? res.data.attributes.image.image_details : [];
                    // this.zhuce = res.data.attributes.person?.date_details ? res.data.attributes.person.date_details?.birth[0].date : '';
                    this.zhuce = res.data.attributes.person?.date_details ? res.data.attributes.person.date_details?.birth[0].date : '';

                    this.person = res.data.attributes.person ? res.data.attributes.person : {};
                    this.watchlisrole = res.data.attributes.watchlist?.role_details ? res.data.attributes.watchlist.role_details : this.watchlisrole;
                    // console.log(``, this.watchlisrole);
                    this.watchlisrole.primary = res.data.attributes?.watchlist?.role_details?.primary ? [res.data.attributes.watchlist?.role_details?.primary] : [];
                } else {
                    this.zhuce = res.data.attributes?.entity.date_details ? res.data.attributes?.entity?.date_details?.registration[0].date : '';
                    // console.log(`zhuce`, this.zhuce);
                    this.zhuce1 = res.data.attributes.entity.date_details ? res.data.attributes.entity.country_territory_details.registration[0] : '';
                    // this.Numbersable = res.data.attributes.entity.identification_details;

                    this.watchlistothertable = res.data.attributes?.list_reference?.other_official_lists ? res.data.attributes?.list_reference?.other_official_lists : [];
                }
                nums = res.data.attributes.person?.identification_details ? res.data.attributes.person?.identification_details : [];
                nums1 = res.data.attributes.watchlist?.identification_details ? res.data.attributes.watchlist?.identification_details : [];
                nums = nums.concat(nums1);
                // console.log(`nums`, nums);
                this.parentTablenum1 = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [].concat(nums);
                this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
                this.str = res.data.attributes.watchlist?.comment_details?.list_comment ? res.data.attributes.watchlist.comment_details?.list_comment : '';
                this.sources = res.data.attributes?.watchlist?.sources ? res.data.attributes.watchlist.sources : [];
                // 制裁列表
                this.watchlisttable = res.data.attributes?.list_reference?.sanctions_lists ? res.data.attributes?.list_reference?.sanctions_lists : [];
                // Country/Territory Details
                this.lianshu = res.data.attributes?.watchlist?.country_territory_details ? res.data.attributes.watchlist.country_territory_details : {};
                // watchilsrt内容
                this.Wathchlist = res.data.attributes?.watchlist?.content_type_details ? res.data.attributes.watchlist.content_type_details : {};
                // 主称

                this.PrimaryNam = res.data.attributes.basic.name_details.primary_name;
                this.zhicaiTable1 = [res.data.attributes.basic.name_details.primary_name];
                // console.log(``, this.zhicaiTable1);
                // 别名
                this.Formerlyable2 = res.data.attributes.basic?.name_details.also_known_as ? res.data.attributes.basic?.name_details.also_known_as : [];
                // 前称
                this.Formerlyable1 = res.data.attributes.basic?.name_details?.formerly_known_as ? res.data.attributes.basic?.name_details?.formerly_known_as : [];
                this.Formerlyable = res.data.attributes.basic?.name_details?.also_known_as ? res.data.attributes.basic.name_details.also_known_as[0].associated_names : [];

                // 制裁名单
                // 档案注解

                // 关系
                this.timers = res.data.attributes?.watchlist.last_reviewed_date ? res.data.attributes.watchlist.last_reviewed_date : '';
                this.timers=new Date(this.timers).toISOString().split('T')[0]
                this.parentTable1 = res.data.attributes?.relationship?.connection_details ? res.data.attributes.relationship.connection_details : [];
                this.tableList = res.data.attributes.relationship?.connection_details ? res.data.attributes.relationship?.connection_details : [];

                if (res.data.attributes?.basic?.address_details == undefined) {
                    res.data.attributes.basic = {};
                    res.data.attributes.basic.address_details = [];
                }
                if (res.data.attributes?.watchlist?.address_details == undefined) {
                    res.data.attributes.watchlist = {};
                    res.data.attributes.watchlist.address_details = [];
                }
                this.addressable = res.data.attributes?.basic?.address_details == undefined && res.data.attributes?.watchlist?.address_details == undefined ? [] : res.data.attributes.basic.address_details.concat(res.data.attributes.watchlist.address_details);
            });
        },

        gotocountry1(row) {
            console.log(row);
            if (row.countryCode != 'n.a.') {
                let routeData = this.$router.resolve({
                    path: '/country?' + '&companyCountry=' + row.countryCode,
                });

                window.open(routeData.href, '_blank');
            }
            //   if(!row.countryCode.includes(';')){
            //   }
        },

        setid(row) {
            // console.log(row.Website);
            if (row.includes('http')) {
                console.log(`true`, true);
                let routeData = row;
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row;
                window.open(routeData, '_blank');
            }
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.flex_align_center {
    display: flex;
    align-items: center;
}
.sna_box {
    margin-top: 60px;
}
.right_coll {
    width: 440px;
}
.coll_item {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
    margin-bottom: 20px;
}
.coll_item1 {
    background-color: rgba(226, 237, 249, 1);
}
.coll_item2 {
    background-color: rgba(239, 239, 239, 1);
}
.coll_item3 {
    background-color: rgba(255, 244, 208, 1);
}
.coll_item4 {
    background-color: rgba(251, 233, 218, 1);
}
.coll_top {
    padding: 12px 30px;
}
.coll_top .img {
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #e6eeff;
    border-radius: 50%;
    text-align: center;
    margin-right: 20px;
}
.coll_top .img2 {
    background: #eee8ff;
}
.coll_top .img3 {
    background: #ffe6f8;
}
.coll_top .img4 {
    background: #fff3e8;
}
.coll_top .img img {
    width: 28px;
}
.coll_top .des .title {
    font-size: 16px;
    color: #8497ab;
    line-height: 18px;
    margin-bottom: 8px;
}
.coll_top .des .txt {
    width: 100px;
    font-size: 14px;
    color: #022955;
    line-height: 18px;
}
.btn_show div {
    display: none;
    margin-top: 10px;
    width: 30px;
    height: 30px;
    line-height: 24px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    text-align: center;
    cursor: pointer;
}
.btn_show img {
    width: 8px;
}
.check_out {
    display: none;
}

.coll_cont {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s;
}
.coll_cont_box {
    position: relative;
    padding-top: 20px;
    margin: 0 30px;
    border-top: 1px solid #e7eaed;
}
.coll_cont.on {
    max-height: 666px;
}
.item {
    font-size: 14px;
    margin-bottom: 14px;
}
.item .lab {
    color: #8497ab;
}
.coll_cont .item {
    position: relative;
}

.coll_cont .item .txt {
    width: 280px;
    text-align: right;
}
.left_mess {
    width: 540px;
    display: flex;
}
.left_mess_bottom {
    margin-top: 20px;
}
.mess_item {
    font-size: 14px;
    margin-bottom: 20px;
}
.mess_item .lab {
    width: 128px;
    color: #8497ab;
}
.mess_item .txt {
    width: 350px;
    color: #022955;
    margin-left: 20px;
}
.mess_item .txt img {
    width: 52px;
    box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    margin-right: 5px;
}
.mess_item .txt a {
    color: #1290c9;
    text-decoration: underline;
}
</style>
<style>
.pop_sic.el-popover--plain {
    padding: 10px !important;
    font-size: 14px;
    color: #022955;
}
.flex_center_between_box {
    display: flex !important;
    /* align-items: center; */

    justify-content: space-between !important;
}
.dialog_normalsuo .flex_center_between_box {
    padding: 0 20px;
}
.echarts {
    position: relative;
}
.aaa {
    position: absolute;
    left: 105px;
    top: 46px;
    font-size: 18px;
}
.aaa2 {
    position: absolute;
    left: 109px;
    top: 46px;
    font-size: 18px;
}
.aaa1 {
    position: absolute;
    left: 99px;
    top: 46px;
    font-size: 18px;
}
.bbb {
    position: absolute;
    left: 105px;
    top: 200px;
    font-size: 18px;
}
.bbb2 {
    position: absolute;
    left: 109px;
    top: 200px;
    font-size: 18px;
}
.bbb1 {
    position: absolute;
    left: 99px;
    top: 200px;
    font-size: 18px;
}
.ddd {
    position: absolute;
    left: 39px;
    top: 140px;
    height: 150px;
    width: 150px;
    z-index: 20;
    cursor: pointer;
}
.detail_cont {
    padding: 0px 20px 20px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
.casca_countone {
    position: absolute;
    left: 160px;
    bottom: 10px;
    z-index: 29;
}
.ecahrt {
    position: relative;
}
.credittext {
    width: 400px;
}
.name-overview-box-text {
    padding: 10px;
}
.information-box,
.information-box-text {
    font-size: 14px;
    color: #022955;
    /* line-height: 25px; */
}
/* .text {
    position: absolute;
    left: 50%;
    top: 50%;
} */
</style>
